class PluginEntity {
  constructor({ ...data }) {
    this.pk = data.pk || '';
    this.isActivated = data.is_activated;
    this.description = (data.plugin_master && data.plugin_master.description) || '';
    this.logo = (data.plugin_master && data.plugin_master.logo) || null;
    this.name = (data.plugin_master && data.plugin_master.name) || '';
    this.apiKey = data.api_key;
    this.authUrl = data.auth_url;
    this.guid = data.guid;
    this.accessToken = data.access_token ? data.access_token.access_token : null;
  }
}

export default PluginEntity;
