import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph } from 'grommet';
import clipArt from './error.svg';

const ExpiredPage = ({ message }) => {
  return (
    <Box
      justify="start"
      background={{
        image: `url(${clipArt})`,
        opacity: true,
      }}
      height="100vh"
      width="100%"
      pad="large"
    >
      <Heading level={1} weight="bold">
        419 | Page Expired
      </Heading>
      <Paragraph size="xlarge">{message}</Paragraph>
    </Box>
  );
};

ExpiredPage.defaultProps = {
  message: 'Sorry, Your session has expired. Please refresh and try again.',
};

ExpiredPage.propTypes = {
  message: PropTypes.string,
};

export default ExpiredPage;
