class ProductVariant {
  constructor({ ...data }) {
    this.pk = data.pk;
    this.dateOfUpload = data.created_at;
    this.uploadedBy = data.subscriber_profile ? data.subscriber_profile.name : '';
    this.totalRecordsUploaded = data.stats && data.stats.child_count;
    this.recordsCreated = data.stats.create_success + data.stats.update_success;
    this.recordsFailed = data.stats.create_failed + data.stats.update_failed;
    this.status = data.status;
    this.fileName = data.stats.original_file_name;
    this.organisation = data.organisation_pk;
    this.createFailed = data?.stats?.create_failed;
    this.updateFailed = data?.stats?.update_failed;
    this.errorDisplay = data?.error_details?.error_display;
  }
}

export default ProductVariant;
