class History {
  constructor(data) {
    this.pk = data.pk;
    this.date = data.created_at;
    this.status = data.status;
    this.errorStatus = data.error_details.error_display ? data.error_details.error_display : '';
  }
}

export default History;
