// const error = { username: 'username is required', last_name: 'last name is required', phone: 'phone is required', roles: 'roles is required'  }
class ErrorReport {
  constructor(data) {
    this.pk = data.pk;
    this.rowIndex = data.object_data.row_index;
    this.error = data.error_details.error_display;
  }
}

export default ErrorReport;
