import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';

import theme from 'granite-admin/core/theme';
import { loadGrandEmitter } from 'granite-admin/utils/grandEmitter';
import PrivateRoute from './PrivateRoute';
import PublicOnlyRoute from 'granite-admin/core/components/PublicOnlyRoute';
import PublicRoute from 'granite-admin/core/components/PublicRoute';
import NotFoundPage from 'granite-admin/core/components/NotFoundPage';
import { ToastProvider } from 'granite-admin/core/components/Toast/ToastProvider';
import { ConfigProvider } from 'granite-admin/core/components/ConfigProvider';

import configureStore from 'granite-admin/utils/ducks/configure-store';
import { loadAuthToken, loadOrganisation, loadMyOrganisations } from 'granite-admin/utils/auth-singleton';
import appRoutes from 'routes';
import projectTheme from 'project_theme';
import config from 'granite-admin/core/config';
import projectConfig from 'project_config';

let routes = appRoutes;

// Init Auth Singleton at load
loadAuthToken();
loadOrganisation();
loadMyOrganisations();
loadGrandEmitter();

// Create our Redux store.
const store = configureStore();

const customTheme = deepMerge(theme, projectTheme);
const customConfig = deepMerge(config, projectConfig);

const App = () => {
  return (
    <ReduxProvider store={store}>
      <Grommet theme={customTheme} full>
        <Router>
          <ConfigProvider config={customConfig}>
            <ToastProvider>
              <Switch>
                {routes.map(route => {
                  if (route.private) return <PrivateRoute key={route.path} addTabBtn={false} {...route} />;
                  else if (route.publicOnly) return <PublicOnlyRoute key={route.path} {...route} />;
                  else return <PublicRoute key={route.path} {...route} />;
                })}
                <Route path="*" render={() => <NotFoundPage />} />
              </Switch>
            </ToastProvider>
          </ConfigProvider>
        </Router>
      </Grommet>
    </ReduxProvider>
  );
};

export default App;
