// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';
import { getAuthToken } from 'granite-admin/utils/auth-singleton';

// Application Imports
import PluginEntity from 'plugins/entities/plugins';

// import AddressEntity from 'granite-admin/organisations/entities/address'

/* eslint-disable */
const url = process.env.REACT_APP_PLUGINS_BASE_URL;

async function getPluginList(params) {
  const payload = { params: { ...params } };
  try {
    const { data } = await GraniteAPIClient(url).get('/plugin/', payload);
    const list = data ? data.results?.map(item => new PluginEntity({ mapValues: true, ...item })) : [];
    return { list, count: data.count };
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function getPluginDetail(id) {
  const payload = {};
  try {
    const { data } = await GraniteAPIClient(url).get(`/plugin/${id}/`, payload);
    const pluginData = new PluginEntity({ mapValues: true, ...data });
    return pluginData;
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function activatePlugin(id) {
  const payload = {};
  try {
    const { data } = await GraniteAPIClient(url).post(`/plugin/${id}/activate/`, payload);
    const pluginData = new PluginEntity({ mapValues: true, ...data });
    return pluginData;
  } catch (e) {
    throw new GraniteError(e);
  }
}

async function deactivatePlugin(id, pluginToken) {
  const payload = {
    data: {
      data: { access_token: getAuthToken() },
    },
  };
  try {
    const { data } = await GraniteAPIClient(url, { Authorization: `Bearer ${pluginToken}` }).delete(
      `/plugin/${id}/activate/`,
      payload,
    );
    return data;
  } catch (e) {
    throw new GraniteError(e);
  }
}

export default {
  getPluginList,
  activatePlugin,
  getPluginDetail,
  deactivatePlugin,
};
