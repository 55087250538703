import Sidebar from 'granite-admin/core/components/Sidebar';

const config = {
  sidebarSettings: {
    align: 'left',
    width: '256px',
    showUserDropMenu: true,
    showUserProfile: false,
    showReferGranite: false,
    SideBarComponent: Sidebar,
    layout: '', // leave empty to obey user pref otherwise use => "top_menu_bar/side_menu_bar"
    leftGroupVisibleItem: 8, // max num of item to show in left group for top navbar
    // getItemCount > a func by which project can decide item count to show as per screen size
  },
  toast: {
    duration: 5000,
    closeOnClick: false,
    showCloseBtn: true,
    maxCount: 5,
  },
  listOpenNewTab: false,
  showCreateOrganisation: false,
  websocketMappings: {
    notifications: {
      //socket action name
      showToast: false,
      autoRedirect: false,
      redirectUrl: '',
      volatileStorage: false, //to store onmessage's data in browser's storage if data is volatile
    },
    widgetDashboard: {
      showToast: true,
      toastMsg: 'Widget is refreshed with updated data',
      autoRedirect: true,
      redirectUrl: '/my-boards/:id/:name',
      volatileStorage: true,
    },
  },
};

export default config;
