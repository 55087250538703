import GraniteError from 'granite-admin/utils/granite-error';
import userApi from 'granite-admin/accounts/gateways/user-api';

async function getMyProfile(eventEmitter) {
  try {
    const profile = await userApi.myProfile();
    return profile;
  } catch (e) {
    throw new GraniteError(e.errors?.title);
  }
}

export { getMyProfile };
