import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Paragraph } from 'grommet';
import styled from 'styled-components';
import clipArt from './error.svg';

const BigHeading = styled(Heading)`
  font-size: 8rem;
  margin-top: 2rem;
`;

const ErrorPage = ({ message }) => {
  return (
    <Box
      justify="start"
      background={{
        image: `url(${clipArt})`,
        opacity: true,
      }}
      height="100vh"
      width="100%"
      pad="large"
      align="center"
    >
      <BigHeading level={1} weight="bold">
        Oops !
      </BigHeading>
      <Paragraph size="xlarge">{message}</Paragraph>
    </Box>
  );
};

ErrorPage.defaultProps = {
  message: 'There seems to be a problem loading this content. Please contact our support team if the issue persists.',
};

ErrorPage.propTypes = {
  message: PropTypes.string,
};

export default ErrorPage;
