// Core Imports
import GraniteError from 'granite-admin/utils/granite-error';
import GraniteAPIClient from 'granite-admin/utils/granite-client';

/* eslint-disable */
import UploadedFileEntity from 'konect/entities/UploadedFile';
import ErrorReportEntity from 'konect/entities/ErrorReport';
import SettingsEntity from 'granite-admin/common/entities/settings';
import { getExtraAPIParams } from 'granite-admin/utils/auth-singleton';
import SourceEntity from 'dashboard/entities/history';
const HISTORY_EXPORT_URL = process.env.REACT_APP_EXPORT_BASE_URL;

const URL = process.env.REACT_APP_KONECT_BASE_URL;

async function getMyDetails(pk) {
  try {
    const { data } = await GraniteAPIClient(URL).get(`plugin-subscriber/${pk}/`);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getPluginUploadHistory(data) {
  const payload = { params: { parent: true, master_name: 'Import CSV', ...data, ...getExtraAPIParams() } };
  try {
    const { data } = await GraniteAPIClient(URL).get('/source-object/', payload);
    const list = data.results.map(item => new UploadedFileEntity(item));
    return { list, count: data.count };
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function getErrorReport(data) {
  const payload = { params: { ...data, ...getExtraAPIParams() } };
  try {
    const { data } = await GraniteAPIClient(URL).get('/source-object/', payload);
    let list = data.results.map(item => new ErrorReportEntity(item));
    list = list.length ? list.filter(i => i.error) : [];
    return { list, count: data.count };
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function downloadErrorReport(fileId) {
  try {
    const { data } = await GraniteAPIClient(URL).get(`source-object/error-report/${fileId}/`);
    return data;
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function getSettingMasterSettings(params) {
  try {
    const { data } = await GraniteAPIClient(URL).get('/plugin-settings-master/', params);
    const list = data.results.map(e => new SettingsEntity(e));
    return list;
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function getMySettings(params) {
  try {
    const { data } = await GraniteAPIClient(URL).get('/plugin-settings/', params);
    return data.results.map(({ pk, value, settings_master, extra_data }) => ({
      pk,
      value: value !== '""' ? value : '',
      name: settings_master?.key,
      code: settings_master?.code,
      masterpk: settings_master?.pk,
      extra_data,
    }));
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function updateSettings(pk, value) {
  const payload = { ...value };
  try {
    const { data } = pk
      ? await GraniteAPIClient(URL).put(`/plugin-settings/${pk}/`, payload)
      : await GraniteAPIClient(URL).post(`/plugin-settings/`, payload);
    return data;
  } catch (error) {
    throw error;
  }
}

async function deleteMySettings(params) {
  try {
    const { data } = await GraniteAPIClient(URL).delete(`/plugin-settings/${params}/`);
    return data;
  } catch (error) {
    throw new GraniteError(error);
  }
}

async function getHistory(params) {
  const payload = { params: { ...params, ...getExtraAPIParams() } };
  try {
    const { data } = await GraniteAPIClient(URL).get('/source-object/', payload);
    if (!params.export) {
      const list = data.results.map(item => new SourceEntity(item));
      return { list, count: data.count };
    } else {
      return data;
    }
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function profileProcess(params) {
  try {
    const { data } = await GraniteAPIClient(URL).get(`/profile-process/`, params);
    return data.results;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

async function getExport(params) {
  const payload = { params };
  try {
    const { data } = await GraniteAPIClient(HISTORY_EXPORT_URL).get(`invoke_export/`, payload);
    return data;
  } catch (e) {
    throw new GraniteError(e.errors);
  }
}

export default {
  getMyDetails,
  getPluginUploadHistory,
  getErrorReport,
  downloadErrorReport,
  getSettingMasterSettings,
  getMySettings,
  updateSettings,
  deleteMySettings,
  getHistory,
  profileProcess,
  getExport,
};
